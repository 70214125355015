import React, {FC, useEffect, useState} from 'react'
import {KTCard, toAbsoluteUrl} from "../../../_metronic/helpers";
import {SubscriptionTable} from "../../modules/tables/userSubs/SubscriptionTable";
import {getDashboardData} from "../../requests/_requests";
import {DashboardData} from "../../_models";

const Dashboard: FC = () => {

    const [data, setData] = useState<DashboardData>()

    useEffect(() => {
        getDashboardData().then(res => {
            if (res.status === 200) {
                setData(res.data?.data)
            }
        })
    }, []);

    return <>
        <div className='row justify-content-evenly gap-5'>
            <div className='col-md-7 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-primary fs-3 fw-bold'>
                        مفتخر میزبانی سرویس ترید شما در بزرگترین و حرفه ای ترین سرویس IP ثابت ایران هستیم!
                    </div>
                    <p className="fs-8">به حساب کاربری کاور ترید خود خوش آمدید :)</p>
                </div>
                <img
                    className='position-relative'
                    style={{top: "-30px"}}
                    src={toAbsoluteUrl('/media/happy-announcement.svg')}
                    alt=''
                />
            </div>
            <div className='col-md-4 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-gray-400 fs-4 fw-bold'>
                        ما را در اینستاگرام دنبال کنید!
                    </div>
                    <a className='btn btn-secondary btn-small-ct text-nowrap' href={data?.instagram}>صفحه اینستگرام کاور
                        ترید</a>
                </div>
                <img
                    className='position-relative'
                    style={{top: "-30px"}}
                    src={toAbsoluteUrl('/media/loving-it.svg')}
                    alt=''
                />
            </div>
            <div className='col-md-7 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-gray-400 fs-3 fw-bold'>
                        آدرس وبسایت رسمی کاور ترید!
                    </div>
                    <a className='btn btn-small-ct btn-secondary text-nowrap' href={data?.clean_website}>cover-trade.com</a>
                </div>
                <img
                    className='p-5'
                    src={toAbsoluteUrl('/media/website.svg')}
                    alt=''
                />
            </div>
            <div className='col-md-4 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-gray-400 fs-4 fw-bold'>
                        عضویت در کانال تلگرام کاورترید!
                    </div>
                    <a className='btn btn-secondary btn-small-ct text-nowrap' href={data?.telegram}>کانال تلگرام کاورترید</a>
                </div>
                <img
                    className='p-5'
                    src={toAbsoluteUrl('/media/social.svg')}
                    alt=''
                />
            </div>

        </div>
        <KTCard className='m-3 my-5'>
            <div className="p-10 pb-0">
                <h4>خلاصه ای از وضعیت اشتراک های شما</h4>
            </div>
            <SubscriptionTable/>
        </KTCard>
    </>
}
export default Dashboard