import React from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {PlanTable} from "../../modules/tables/plans/PlanTable";

const PlansPage = () => {

    const breadcrumbs = [
        {
            title: 'پنل ادمین',
            path: "/admin",
            isActive: true
        },
        {
            title: 'مدیریت پلن ها',
            path: "/admin/plans",
            isActive: true
        }
    ]
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                مدیریت پلن ها
            </PageTitle>
            <PlanTable/>
        </EnableSidebar>
    )
}

export {PlansPage}
