/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {otpRequest} from '../../../requests/_requests'
import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import {useAuth} from '../core/Auth'
import AccountPrompt from "./partials/AccountPrompt";

const iranPhoneRegex = /^(?:\+98|0098|98|0)?(9\d{2})[-\s.]?(\d{3})[-\s.]?(\d{4})$/

const tr = {
    invalidPhone: {
        id: "AUTH.VALIDATION.INVALID_PHONE"
    },
    required: {
        id: "VALIDATION.REQUIRED"
    },
    phone: {
        id: 'AUTH.INPUT.PHONE'
    },
    register: {
        id: 'AUTH.REGISTER.TITLE'
    },
    haveAccount: {
        id: 'AUTH.GENERAL.HAVE_ACCOUNT'
    },
    next: {
        id: 'NEXT'
    }
};

export function OtpRequest() {
    const [loading, setLoading] = useState(false)
    const [phoneSubmitted, setPhoneSubmitted] = useState(false)
    const {formatMessage: text} = useIntl()
    const navigate = useNavigate();
    const { phone, setPhone } = useAuth()
    const {currentUser} = useAuth()

    const phoneSchema = Yup.object().shape({
        phone: Yup.string()
            .matches(iranPhoneRegex, text(tr.invalidPhone))
            .required(text(tr.required, {name: text(tr.phone)}))
    })

    const formik = useFormik({
        initialValues: { phone: phone || '' },
        validationSchema: phoneSchema,
        onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
            setLoading(true)
            try {
                await otpRequest(values.phone)
                    .then(res => {
                        navigate('/auth/otp/verify')
                    })
            } catch (error: any) {
                setErrors(error.response.data.errors)
                setStatus(error.response.data.errors.phone)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        if (formik.values.phone !== phone) {
            setPhone(formik.values.phone)
        }
    }, [formik.values.phone, phone, setPhone])

    useEffect(() => {
        if (currentUser){
            navigate('/dashboard')
        }
    }, []);

    return (
        <form
            dir='rtl'
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
        >
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder'>
                {text(tr.register)}
            </h1>
            <AccountPrompt/>
            {/* end::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mt-15 mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                    {text(tr.phone)}
                </label>
                <input
                    dir='ltr'
                    placeholder=''
                    {...formik.getFieldProps('phone')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.phone && formik.errors.phone},
                        {
                            'is-valid': formik.touched.phone && !formik.errors.phone,
                        }
                    )}
                    type='phone'
                    name='phone'
                />
                {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.phone}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='d-flex justify-content-end mb-10'>
                <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading &&
                        <span className='indicator-label'>
                            {text(tr.next)}
                        </span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            {text(tr.next)}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}


        </form>
    )
}
