// @ts-nocheck
import {Column} from 'react-table'
import {User} from "../../../_models";
import {UserCustomHeader} from "../../apps/user-management/users-list/table/columns/UserCustomHeader";
import {UserActionsCell} from "../columns/UserActionsCell";
import {ColumnHeader} from "../columns/ColumnHeader";
import {TextCell} from "../columns/TextCell";
import {BadgeCell} from "../columns/BadgeCell";

const UserColumns: ReadonlyArray<Column<User>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='ID' className='min-w-125px' />,
        id: 'id',
        Cell: ({...props}) => <BadgeCell text={props.data[props.row.index]?.id} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام' className='min-w-125px' />,
        id: 'name',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.name} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='موبایل' className='min-w-125px' />,
        id: 'phone',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.phone} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='ایمیل' className='min-w-125px' />,
        id: 'email',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.email} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تاریخ عضویت' className='min-w-125px' />,
        id: 'created_at',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index]?.created_at} />,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='عملیات' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
    },
]

export {UserColumns}
