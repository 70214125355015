/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {ID, KTIcon} from "../../../../_metronic/helpers";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {useQueryResponseSubs} from "./AdminSubQueryResponseProvider";
import {useListView} from "./AdminSubListViewProvider";
import {reactivateSub} from "../../../requests/_admin_requests";

type Props = {
    id: ID,
    status: any
}

const AdminSubActionCell: FC<Props> = ({id, status}) => {
    const {setItemIdForUpdate, setSecondModal, setThirdModal, setMessage} = useListView()
    const {refetch} = useQueryResponseSubs()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }

    const openExtendModal = () => {
        if (setSecondModal) {
            setSecondModal(id)
        }
    }

    const openSuspendModal = () => {
        if (setThirdModal) {
            setThirdModal(id)
        }
    }

    const reactivate = () => {
        reactivateSub(id).then(res => {
            if (setMessage) {
                setMessage({
                    type: 'success',
                    text: res?.data?.data?.message
                })
                refetch()
                setTimeout(() => setMessage(undefined), 7000)
            }
        })
    }

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                عملیات
                <KTIcon iconName='down' className='fs-5 m-0'/>
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={openExtendModal}>
                        تمدید
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a
                        className='menu-link px-3'
                        data-kt-plans-table-filter='edit_row'
                        onClick={openEditModal}
                    >
                        ویرایش
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                {
                    status === 2
                    &&
                    <div className='menu-item px-3'>
                        <a className='menu-link px-3 text-danger' onClick={openSuspendModal}>
                            مسدود کردن
                        </a>
                    </div>
                }
                {
                    status === 4
                    &&
                    <div className='menu-item px-3'>
                        <a className='menu-link px-3 text-success' onClick={reactivate}>
                            رفع مسدودیت
                        </a>
                    </div>
                }

                {/* end::Menu item */}

            </div>
            {/* end::Menu */}
        </>
    )
}

export {AdminSubActionCell}
