import React from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard} from "../../../_metronic/helpers";
import {CouponsTable} from "../../modules/tables/coupons/CouponsTable";

const CouponsPage = () => {

    const breadcrumbs = [
        {
            title: 'پنل ادمین',
            path: "/admin/dashboard",
            isActive: true
        },
        {
            title: 'کد های تخفیف',
            path: "/admin/coupons",
            isActive: true
        }
    ]
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                کد های تخفیف
            </PageTitle>
            <CouponsTable/>
        </EnableSidebar>
    )
}

export {CouponsPage}
