import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  const intl = useIntl()

  return (
    <div dir='rtl' className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-75 p-md-10 order-2 order-lg-1 bg-light-primary'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px w-xl-700px p-10 mh-md-800px'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-9 fs-md-base'>
            <a href='#' className='px-5' target='_blank'>
            {intl.formatMessage({id: 'TERMS-AND-CONDITIONS'})}
            </a>

            <a href='#' className='px-5' target='_blank'>
            {intl.formatMessage({id: 'SUBSCRIPTIONS'})}
            </a>

            <a href='#' className='px-5' target='_blank'>
              {intl.formatMessage({id: 'CONTACT'})}
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid align-items-end w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'>
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center pt-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logo.svg')} className='h-140px' />
          </Link>
          {/* end::Logo */}

          {/* begin::Text */}
          <div className='fs-lg fw-bolder text-center text-info pb-20 px-5 w-100'>   
          {
            intl.formatMessage({id: 'AUTH.GENERAL.MSG'})
                .split('<br>').map((line, index) => (
                  <div key={index}>{line}{<br/>}</div>
                ))
          }
          </div>
          {/* end::Text */}

          {/* begin::Image */}
          <img
            className='mw-100 mb-10'
            src={toAbsoluteUrl('/media/auth-screens.svg')}
            alt='covertrade'
          />
          {/* end::Image */}

        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
