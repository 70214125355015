import {useMemo} from 'react'
import {Row, useTable} from 'react-table'
import {CustomHeaderColumn} from '../columns/CustomHeaderColumn'
import {CustomRow} from '../columns/CustomRow'
import {Invoice} from '../../../_models'
import {ListLoading} from '../ListLoading'
import {KTCardBody} from "../../../../_metronic/helpers";

import {
    InvoicesQueryResponseProvider,
    useQueryResponseDataInvoice,
    useQueryResponseLoading
} from "./InvoicesQueryResponseProvider";
import {InvoiceColumns} from "./_invoice_table_columns";
import {QueryRequestProvider} from "../core/QueryRequestProvider";

const InvoiceTableInner = () => {
    const invoices = useQueryResponseDataInvoice()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => invoices, [invoices])
    const columns = useMemo(() => InvoiceColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })

    return (
        <>
            <KTCardBody className='py-4'>
                <div className='table-responsive'>
                    <table
                        id='invoice-table'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        {...getTableProps()}
                    >
                        <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column) => (
                                <CustomHeaderColumn key={column.id} column={column}/>
                            ))}
                        </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<Invoice>, i) => {
                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div
                                        className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        تا کنون پرداختی نداشته اید.
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                {isLoading && <ListLoading/>}
            </KTCardBody>
        </>
    )
}

const InvoiceTable = () => {
    return <QueryRequestProvider>
        <InvoicesQueryResponseProvider>
            <InvoiceTableInner/>
        </InvoicesQueryResponseProvider>
    </QueryRequestProvider>
}
export {InvoiceTable}
