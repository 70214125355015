/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useAuth} from "../core/Auth";
import {getUserByToken, login} from "../../../requests/_requests";

const loginSchema = Yup.object().shape({
    phone: Yup.string()
        .required('وارد کردن شماره تلفن ضروری است.'),
    password: Yup.string()
        .required('لطفا رمز عبور را وارد کنید.'),
})

const initialValues = {
    phone: '',
    password: '',
}

export function Login() {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser, currentUser} = useAuth()
    const intl = useIntl()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting, setErrors, setFieldError}) => {
            setLoading(true)
            try {
                const {data: auth} = await login(values?.phone, values?.password)
                saveAuth(auth)
                getUserByToken(auth.api_token).then((res) => {
                    setCurrentUser(res.data?.data)
                    navigate('/dashboard')
                })
            } catch (e: any) {
                const {errors, message} = e.response?.data
                setErrors(errors)
                if (!errors) {
                  if (e.status === 404){
                    setErrors({phone: 'کاربری با این شماره یافت نشد.'})
                  }else{
                    setErrors({password: message})
                  }
                }
                setStatus(message)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard')
        }
    }, []);
  console.log(formik)

    return (
        <form
            dir='rtl'
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder'>
                ورود به پنل کاربری
            </h1>
            {/* end::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mt-15 mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                    شماره موبایل
                </label>
                <input
                    {...formik.getFieldProps('phone')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched?.phone && formik.errors?.phone},
                        {
                            'is-valid': formik.touched?.phone && !formik.errors?.phone,
                        }
                    )}
                    name='phone'
                    autoComplete='off'
                />
                {formik.touched.phone && formik.errors?.phone && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.phone}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                    {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    name='password'
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched?.password && formik.errors?.password,
                        },
                        {
                            'is-valid': formik.touched?.password && !formik.errors?.password,
                        }
                    )}
                />
                {formik.touched?.password && formik.errors?.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='d-grid my-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>ورود به پنل کاربری</span>}
                    {loading && (<span className='indicator-progress' style={{display: 'block'}}>
                          لطفا منتظر بمانید...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
            </div>
            {/* end::Action */}


        </form>
    )
}
