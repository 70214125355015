// @ts-nocheck
import {Column} from 'react-table'
import {Coupon} from "../../../_models";
import {UserCustomHeader} from "../../apps/user-management/users-list/table/columns/UserCustomHeader";
import {ColumnHeader} from "../columns/ColumnHeader";
import {TextCell} from "../columns/TextCell";
import {BadgeCell} from "../columns/BadgeCell";
import {StatusCell} from "../columns/StatusCell";
import {CouponActionCell} from "./CouponActionCell";

const CouponColumns: ReadonlyArray<Column<Coupon>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='ID' className='min-w-125px' />,
        id: 'id',
        Cell: ({...props}) => <BadgeCell text={props.data[props.row.index]?.id} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='کد تخفیف' className='min-w-125px' />,
        id: 'code',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.code} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='توضییحات' className='min-w-125px' />,
        id: 'description',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.description} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='وضعیت' className='min-w-125px' />,
        id: 'status',
        Cell: ({...props}) => <StatusCell is_active={props?.data[props.row.index]?.is_active} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تاریخ عضویت' className='min-w-125px' />,
        id: 'created_at',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index]?.created_at} />,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='عملیات' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <CouponActionCell id={props.data[props.row.index].id}/>,
    },
]

export {CouponColumns}
