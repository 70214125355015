import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from './AuthLayout'
import {OtpRequest} from './components/OtpRequest'
import {OtpVerify} from "./components/OtpVerify";
import {Login} from "./components/Login";

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout/>}>
            <Route path='otp' element={<OtpRequest/>}/>
            <Route path='otp/verify' element={<OtpVerify/>}/>
            <Route path='login' element={<Login/>}/>
            <Route index element={<OtpRequest/>}/>
        </Route>
    </Routes>
)

export {AuthPage}
