import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useAuth} from "../../../../app/modules/auth";

const Topbar: FC = () => {
    const {currentUser} = useAuth()

    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            <div className='topbar d-flex align-items-stretch flex-shrink-0'>

                {/* begin::User */}
                <div
                    className='d-flex align-items-center py-2 px-2 px-md-3'
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                >
                    <div className='d-none d-md-flex flex-column align-items-end justify-content-center me-2'>
                        <span className='text-white fs-7 fw-bold lh-1 mb-2'>{currentUser?.name} عزیز</span>
                        <span className='text-white fs-base fw-bolder lh-1'>به پنل کاور ترید خوش آمدید!</span>
                    </div>

                    <div className='symbol symbol-circle symbol-30px symbol-md-40px'>
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='avatar'/>
                    </div>
                </div>
                {/* end::User */}

            </div>
        </div>
    )
}

export {Topbar}
