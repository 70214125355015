import axios, {AxiosResponse} from 'axios'
import {adminGetAuth} from "../modules/auth/core/AuthHelpers";
import {
    Coupon,
    CouponsQueryResponse, Plan, Radius, Setting,
    SettingsQueryResponse, Subscription,
    SubscriptionsQueryResponse, User,
    UsersQueryResponse
} from "../_models";
import {ID, Response} from "../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL
const api_token = adminGetAuth()?.api_token
const LOGIN_REQUEST = `${API_URL}/public/admin/login`
const GET_ADMIN_USER = `${API_URL}/admin/me`
const SUB_URL = `${API_URL}/admin/accounts`
const COUPON_URL = `${API_URL}/admin/coupons`
const SETTING_URL = `${API_URL}/admin/settings`
const USER_URL = `${API_URL}/admin/users`
const PLAN_URL = `${API_URL}/admin/plans`
const ADD_PLAN_TO_USER = `${API_URL}/admin/accounts/attach-to-user`
const RADIUS_URL = `${API_URL}/admin/radii`
const DASHBOARD_URL = `${API_URL}/admin/dashboard`

export function loginRequest(email: string, password: string) {
    return axios.post(LOGIN_REQUEST, {
        email, password
    })
}

export function getAdmin(token: string) {
    return axios.get(GET_ADMIN_USER, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const getUsers = (query: string): Promise<UsersQueryResponse> => {
    return axios
        .get(`${USER_URL}?${query}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

export const adminGetSubs = (query: string): Promise<SubscriptionsQueryResponse> => {
    return axios
        .get(`${SUB_URL}?${query}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((d: AxiosResponse<SubscriptionsQueryResponse>) => d.data)
}

export const adminGetCoupons = (query: string): Promise<CouponsQueryResponse> => {
    return axios
        .get(`${COUPON_URL}?${query}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((d: AxiosResponse<CouponsQueryResponse>) => d.data)
}

export const adminGetAllSetting = (query: string): Promise<SettingsQueryResponse> => {
    return axios
        .get(`${SETTING_URL}?${query}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((d: AxiosResponse<SettingsQueryResponse>) => d.data)
}

export const adminPutSetting = (setting: Setting): Promise<SettingsQueryResponse> => {
    return axios
        .put(`${SETTING_URL}`, {
            key: setting.key,
            value: setting.value,
        }, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
}

export const adminGetSingleSetting = (key) => {
    return axios
        .get(`${SETTING_URL}/${key}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
}

export const getUserById = (id: ID): Promise<User | undefined> => {
    return axios
        .get(`${USER_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

export const createUser = (user: User): Promise<AxiosResponse | undefined> => {
    return axios
        .post(USER_URL, user, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
    // .then((response: AxiosResponse<Response<User>>) => response.data)
    // .then((response: Response<User>) => response.data)
}

export const updateUser = (user: User): Promise<AxiosResponse | undefined> => {
    return axios
        .patch(`${USER_URL}/${user.id}`, user, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
    // .then((response: AxiosResponse<Response<User>>) => response.data)
    // .then((response: Response<User>) => response.data)
}

export const deleteUser = (userId: ID): Promise<void> => {
    return axios.delete(`${USER_URL}/${userId}`, {
        headers: {
            'Authorization': `Bearer ${api_token}`
        }
    }).then(() => {
    })
}

export const getPlans = () => {
    return axios.get(`${PLAN_URL}`, {
        headers: {
            'Authorization': `Bearer ${api_token}`
        }
    })
}

export const addPlanToUser = (plan_id: string | number, user_id: any) => {
    return axios.post(`${ADD_PLAN_TO_USER}`, {
        plan_id,
        user_id
    }, {
        headers: {
            'Authorization': `Bearer ${api_token}`
        }
    })
}

export const subscriptionExtension = (id: ID | number, days: number) => {
    return axios.post(`${SUB_URL}/${id}/extension`, {
        days
    }, {
        headers: {
            'Authorization': `Bearer ${api_token}`
        }
    })
}

export const getPlanById = (id: ID): Promise<Plan | undefined> => {
    return axios
        .get(`${PLAN_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((response: AxiosResponse<Response<Plan>>) => response.data)
        .then((response: Response<Plan>) => response.data)
}

export const createPlan = (plan: Plan): Promise<AxiosResponse | undefined> => {
    return axios
        .post(PLAN_URL, plan, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
}

export const updatePlan = (plan: Plan): Promise<AxiosResponse | undefined> => {
    return axios
        .patch(`${PLAN_URL}/${plan.id}`, plan, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
}

export const deletePlan = (id: ID): Promise<void> => {
    return axios.delete(`${PLAN_URL}/${id}`, {
        headers: {
            'Authorization': `Bearer ${api_token}`
        }
    }).then(() => {
    })
}

export const createCoupon = (coupon: Coupon): Promise<AxiosResponse | undefined> => {
    return axios
        .post(COUPON_URL, coupon, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
}

export const updateCoupon = (coupon: Coupon): Promise<AxiosResponse | undefined> => {
    return axios
        .patch(`${COUPON_URL}/${coupon.id}`, coupon, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
}

export const deleteCoupon = (id: ID): Promise<void> => {
    return axios.delete(`${COUPON_URL}/${id}`, {
        headers: {
            'Authorization': `Bearer ${api_token}`
        }
    })
}

export const getCouponById = (id: ID): Promise<Coupon | undefined> => {
    return axios
        .get(`${COUPON_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((response: AxiosResponse<Response<Coupon>>) => response.data)
        .then((response: Response<Coupon>) => response.data)
}

export const getRadius = async (query: string): Promise<Response<Array<Radius>>> => {
    const d = await axios
        .get(`${RADIUS_URL}?${query}`);
    return d.data;
}

export const createRadius = (radius: Radius): Promise<AxiosResponse | undefined> => {
    return axios
        .post(RADIUS_URL, radius)
}

export const updateRadius = (radius: Radius): Promise<AxiosResponse | undefined> => {
    return axios
        .patch(`${RADIUS_URL}/${radius.id}`, radius)
}

export const deleteRadius = (id: ID): Promise<void> => {
    return axios.delete(`${RADIUS_URL}/${id}`)
}

export const getRadiusById = (id: ID): Promise<Radius | undefined> => {
    return axios
        .get(`${RADIUS_URL}/${id}`)
        .then((response: AxiosResponse<Response<Radius>>) => response.data)
        .then((response: Response<Radius>) => response.data)
}

export const getAdminDashboardData = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${DASHBOARD_URL}`)
}

export const getSubById = (id: ID): Promise<Subscription | undefined> => {
    return axios.get(`${SUB_URL}/${id}`)
        .then((response: AxiosResponse<Response<Subscription>>) => response.data)
        .then((response: Response<Subscription>) => response.data)
}

export const updateSub = (sub: Subscription): Promise<AxiosResponse<any>> => {
    return axios.put(`${SUB_URL}/${sub.id}`, sub)
}

export const suspendSub = (id: ID): Promise<AxiosResponse<any>> => {
    return axios.post(`${SUB_URL}/${id}/suspend`)
}

export const reactivateSub = (id: ID): Promise<AxiosResponse<any>> => {
    return axios.post(`${SUB_URL}/${id}/reactivate`)
}

export const syncRadius = (id: ID): Promise<AxiosResponse<any>> => {
    return axios.post(`${RADIUS_URL}/${id}/sync`)
}

export const rebuildRadius = (id: ID): Promise<AxiosResponse<any>> => {
    return axios.post(`${RADIUS_URL}/${id}/rebuild`)
}

export const cleanRadius = (id: ID): Promise<AxiosResponse<any>> => {
    return axios.post(`${RADIUS_URL}/${id}/clean`)
}
