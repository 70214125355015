import React, {useEffect, useState} from 'react'
import {KTIcon} from "../../../../_metronic/helpers";
import {useListView} from "./RadiusListViewProvider";
import {syncRadius} from "../../../requests/_admin_requests";

const RadiusSyncModal = () => {
    const {
        secondModal,
        setSecondModal,
        setMessage
    } = useListView()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const cancel = () => {
        if (setSecondModal) {
            setSecondModal(undefined)
        }
    }

    const call = () => {
        syncRadius(secondModal)
            .then(res => {
                if (setMessage) {
                    setMessage({
                        type: 'success',
                        text: res?.data?.data?.message
                    })
                    setTimeout(() => setMessage(undefined), 7000)
                }
                cancel()
            })
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>همگام سازی سرور ردیوس</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => cancel()}
                                style={{cursor: 'pointer'}}
                            >
                                <KTIcon iconName='cross' className='fs-1'/>
                            </div>
                            {/* end::Close */}
                        </div>

                        <div className='text-center py-15'>

                            <div className="mb-9">
                                سطرهایی که در کاورترید موجود هستند و در این سرور ردیوس وجود ندارند، ایجاد خواهند شد.
                            </div>

                            <button
                                type='reset'
                                onClick={() => cancel()}
                                className='btn btn-light me-3'
                                data-kt-users-modal-action='cancel'
                                disabled={isSubmitting}
                            >
                                لغو
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={call}
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>همگام سازی</span>
                                {(isSubmitting) && (
                                    <span className='indicator-progress'>منتظر باشید...{' '}<span
                                        className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                )}
                            </button>
                        </div>
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export {RadiusSyncModal}
