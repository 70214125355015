import React from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {UsersTable} from "../../modules/tables/users/UsersTable";

const UsersPage = () => {

    const breadcrumbs = [
        {
            title: 'پنل ادمین',
            path: "/admin",
            isActive: true
        },
        {
            title: 'مدیریت کاربران',
            path: "/admin/users",
            isActive: true
        }
    ]
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                مدیریت کاربران
            </PageTitle>
            <UsersTable/>
        </EnableSidebar>
    )
}

export {UsersPage}
