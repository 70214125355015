import axios from 'axios'
import {AuthModel, UserModel} from '../modules/auth/core/_models'
import {getAuth} from "../modules/auth/core/AuthHelpers";
import {Invoice, InvoicesQueryResponse, Subscription, SubscriptionsQueryResponse, User} from "../_models";
import {AxiosResponse} from "axios";
import {ID, Response} from "../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL
const api_token = getAuth()?.api_token
const OTP_REQUEST = `${API_URL}/public/client/otps`
const OTP_VERIFY = `${API_URL}/public/client/otps/verify`
const GET_USER_BY_TOKEN_URL = `${API_URL}/client/profile`
const LOGIN_URL = `${API_URL}/public/client/login`
const REGISTER_URL = `${API_URL}/register`
const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
const GET_SUBSCRIPTIONS = `${API_URL}/public/plans`
const CHECK_COUPON = `${API_URL}/client/coupons/check`
const ADD_PLAN_INVOICE = `${API_URL}/client/invoices/add-item/`
const PUT_ACCOUNT = `${API_URL}/client/accounts/`
const PUT_PROFILE = `${API_URL}/client/profile`
const PATCH_PASSWORD = `${API_URL}/client/profile/password`
const GET_OPEN_INVOICE = `${API_URL}/client/invoices/action/get-open-invoice`
const GET_INVOICE = `${API_URL}/client/invoices/`
const POST_PAY = `${API_URL}/client/invoices/{id}/pay`
const GET_SUBS_URL = `${API_URL}/client/accounts`
const GET_INVOICES_URL = `${API_URL}/client/invoices`
const DASHBOARD_URL = `${API_URL}/client/dashboard`
const PROFILE = `${API_URL}/client/profile/page`

export function otpRequest(phone: string) {
    return axios.post(OTP_REQUEST, {
        phone
    })
}

export function otpVerify(otp: string, phone: string) {
    return axios.post(OTP_VERIFY, {
        otp, phone
    })
}

export function login(phone: string, password: string) {
    return axios.post(LOGIN_URL, {
        phone,
        password,
    })
}

// Server should return AuthModel
export function register(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(REGISTER_URL, {
        email,
        first_name: firstname,
        last_name: lastname,
        password,
        password_confirmation,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    })
}

export function getUserByToken(token: string) {
    return axios.get(GET_USER_BY_TOKEN_URL, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export async function getSubscriptions() {
    const d = await axios
        .get(GET_SUBSCRIPTIONS);
    return d.data;
}

export function checkCoupon(subId: number, coupon: string) {
    return axios.post(CHECK_COUPON, {
            plan_id: subId,
            coupon
        },
        {
            headers: {
                'Authorization': `Bearer ${api_token}`
            },
        })
}

export function addPlanToInvoice(subId: number, coupon: string | undefined) {
    return axios.post(ADD_PLAN_INVOICE + subId, {
            coupon
        },
        {
            headers: {
                'Authorization': `Bearer ${api_token}`
            },
        })
}

export function updateSubscriptionAuth(subId, username: string | null = null, password) {
    return axios.put(PUT_ACCOUNT + subId, {
            username, password
        },
        {
            headers: {
                'Authorization': `Bearer ${api_token}`
            },
        })
}

export function updateProfile(name: string, email: string | null = null) {
    return axios.patch(PUT_PROFILE, {
            name, email
        },
        {
            headers: {
                'Authorization': `Bearer ${api_token}`
            },
        })
}

export function updatePassword(current_password: string | null = null, new_password: string) {
    return axios.patch(PATCH_PASSWORD, {
        new_password, current_password
    })
}

export function getOpenInvoice() {
    return axios.get(GET_OPEN_INVOICE,
        {
            headers: {
                'Authorization': `Bearer ${api_token}`
            },
        })
}

export function getInvoice(id: string | null) {
    return axios.get(GET_INVOICE + id,
        {
            headers: {
                'Authorization': `Bearer ${api_token}`
            },
        })
}

export function payInvoice(invoiceId: string) {
    return axios.post(POST_PAY.replace('{id}', invoiceId), {},
        {
            headers: {
                'Authorization': `Bearer ${api_token}`
            },
        })
}

export const getSubs = (query: string): Promise<SubscriptionsQueryResponse> => {
    return axios
        .get(`${GET_SUBS_URL}?${query}`)
        .then((d: AxiosResponse<SubscriptionsQueryResponse>) => d.data)
}

export const getInvoices = (query: string): Promise<InvoicesQueryResponse> => {
    return axios
        .get(`${GET_INVOICES_URL}?${query}`)
        .then((d: AxiosResponse<InvoicesQueryResponse>) => d.data)
}

export const getSub = (id: ID): Promise<Subscription | undefined> => {
    return axios
        .get(`${GET_SUBS_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((response: AxiosResponse<Response<Subscription>>) => response.data)
        .then((response: Response<Subscription>) => response.data)
}

export const getInvoiceFromSub = (id: ID): Promise<Invoice | undefined> => {
    return axios
        .get(`${GET_SUBS_URL}/${id}/invoice`, {
            headers: {
                'Authorization': `Bearer ${api_token}`
            }
        })
        .then((response: AxiosResponse<Response<Invoice>>) => response.data)
        .then((response: Response<Invoice>) => response.data)
}

export const getDashboardData = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${DASHBOARD_URL}`)
}

export const getProfile = (): Promise<UserModel | undefined> => {
    return axios.get(PROFILE)
        .then((response: AxiosResponse<Response<UserModel>>) => response.data)
        .then((response: Response<UserModel>) => response.data)
}
