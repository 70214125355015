import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Coupon} from "../../../../_models";
import {isNotEmpty} from "../../../../../_metronic/helpers";
import {createCoupon, updateCoupon} from "../../../../requests/_admin_requests";
import clsx from "clsx";
import {ListLoading} from "../../ListLoading";
import {useQueryResponseCoupons} from "../CouponsQueryResponseProvider";
import {useListView} from "../CouponListViewProvider";

type Props = {
    isCouponLoading: boolean
    coupon: Coupon
}

const editCouponSchema = Yup.object().shape({
    title: Yup.string(),
    description: Yup.string(),
    code: Yup.string()
        .max(50, 'کد تخفیف می‌تواند حداکثر ۵۰ کاراکتر باشد.')
        .required('وارد کردن کد تخفیف ضروری است.'),
    amount: Yup.number()
        .required('وارد کردن مبلغ کد تخفیف ضروری است.'),
    minimum_purchase: Yup.number()
        .required('وارد کردن حداقل مبلغ پرداخت ضروری است.'),
    max_discount: Yup.number()
        .required('وارد کردن حداکثر مبلغ تخفیف ضروری است.'),
})

const CouponEditModalForm: FC<Props> = ({coupon, isCouponLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponseCoupons()

    const [couopnForEdit] = useState<Coupon>({
        ...coupon
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: couopnForEdit,
        validationSchema: editCouponSchema,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await updateCoupon(values).then(res => {
                        if (res?.status == 200) {
                            cancel(true)
                        }
                    })
                } else {
                    await createCoupon(values).then(res => {
                        if (res?.status == 201) {
                            cancel(true)
                        }
                    })
                }
            } catch (e: any) {
                setErrors(e.response?.data?.errors)
            } finally {
                setSubmitting(true)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_couopn_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_couopn_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_couopn_header'
                    data-kt-scroll-wrappers='#kt_modal_add_couopn_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>عنوان</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            placeholder='مثلا: کمپین فلان'
                            {...formik.getFieldProps('title')}
                            type='text'
                            name='name'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.title && formik.errors?.title},
                                {
                                    'is-valid': formik.touched.title && !formik.errors?.title,
                                }
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isCouponLoading}
                        />
                        {formik.touched.title && formik.errors?.title && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.title}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>توضیحات</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <textarea
                            {...formik.getFieldProps('description')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.description && formik.errors?.description},
                                {
                                    'is-valid': formik.touched.description && !formik.errors?.description,
                                }
                            )}
                            name='description'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isCouponLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.description && formik.errors?.description && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.description}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>کد تخفیف</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('code')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.code && formik.errors?.code},
                                {
                                    'is-valid': formik.touched?.code && !formik.errors?.code,
                                }
                            )}
                            type='text'
                            name='code'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isCouponLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.code && formik.errors?.code && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.code}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>مبلغ</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('amount')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.amount && formik.errors?.amount},
                                {
                                    'is-valid': formik.touched?.amount && !formik.errors?.amount,
                                }
                            )}
                            type='number'
                            name='amount'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isCouponLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.amount && formik.errors?.amount && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.amount}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>حداقل مبلغ
                            <span className='fs-8'> (حداقل مبلغ فاکتوری که کد تخفیف قابل اعمال بر آن باشد) </span>
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('minimum_purchase')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.minimum_purchase && formik.errors?.minimum_purchase},
                                {
                                    'is-valid': formik.touched?.minimum_purchase && !formik.errors?.minimum_purchase,
                                }
                            )}
                            type='number'
                            name='minimum_purchase'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isCouponLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.minimum_purchase && formik.errors?.minimum_purchase && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.minimum_purchase}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>حداکثر مبلغ تخفیف</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('max_discount')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.max_discount && formik.errors?.max_discount},
                                {
                                    'is-valid': formik.touched?.max_discount && !formik.errors?.max_discount,
                                }
                            )}
                            type='number'
                            name='max_discount'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isCouponLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.max_discount && formik.errors?.max_discount && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.max_discount}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>وضعیت</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <div className="form-check form-check-custom form-check-solid">
                            <input
                                {...formik.getFieldProps('is_active')}
                                className={clsx(
                                    'form-check-input',
                                    {'is-invalid': formik.touched?.is_active && formik.errors?.is_active},
                                    {
                                        'is-valid': formik.touched?.is_active && !formik.errors?.is_active,
                                    }
                                )}
                                checked={formik.values.is_active}
                                disabled={formik.isSubmitting || isCouponLoading}
                                type="checkbox"
                                id="is_active"/>
                            <label className="form-check-label" htmlFor="is_active">
                                فعال
                            </label>
                        </div>
                        {/* end::Input */}
                        {formik.touched?.is_active && formik.errors?.is_active && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.is_active}</span>
                                </div>
                            </div>
                        )}

                    </div>
                    {/* end::Input group */}

                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-couopns-modal-action='cancel'
                        disabled={formik.isSubmitting || isCouponLoading}
                    >
                        لغو
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-couopns-modal-action='submit'
                        disabled={isCouponLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isCouponLoading) && (
                            <span className='indicator-progress'>
                لطفا منتظر بمانید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isCouponLoading) && <ListLoading/>}
        </>
    )
}

export {CouponEditModalForm}
