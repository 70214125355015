import React, {FC} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";

const SupportPage: FC = () => {

    const breadcrumbs = [
        {
            title: 'پنل کاربری',
            path: "/",
            isActive: true
        },
        {
            title: 'پشتیبانی آنلاین',
            path: "/support",
            isActive: true
        }
    ]
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
               پشتیبانی آنلاین کاور ترید
            </PageTitle>
            <div className="w-75">
                در این صفحه می‌توانید روش های ارتباط با پشتیبانی کاور ترید را بررسی کنید و با توجه به نیاز خود با پشتیبانان ما در تماس باشید. کارشناسان ما هر روز هفته از ساعت ۸ صبح الی ۲۴ آماده پاسخگویی به مشکلات، سوالات و پیشنهادات و انتقادات شما کاربر گرامی هستند.
            </div>
            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">برای ارتباط با کارشناسان کاور ترید، روی یکی از دکمه های زیر کلیک کنید</h2>
                </div>
                <KTCardBody className='py-4'>
                    <div className='d-flex'>
                        <div className='w-75 w-100'>
                            <button className="btn btn-primary btn-sm m-5">ورود به پشتیبانی هوشمند و دریافت اپلیکیشن ها و سرور ها</button>
                            <button className="btn btn-primary btn-sm">دریافت اپلیکیشن ها و سرور ها</button>
                        </div>
                        <div className='w-md-25'>
                            <img
                                className='position-md-absolute d-none d-md-flex'
                                style={{bottom: 0}}
                                src={toAbsoluteUrl('/media/support-illustration.svg')}
                                alt='پشتیبانی'
                            />
                        </div>
                    </div>
                </KTCardBody>
            </KTCard>
        </EnableSidebar>
    )
}

export {SupportPage}
