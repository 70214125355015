import React, {FC} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";

const InstructionPage: FC = () => {

    const breadcrumbs = [
        {
            title: 'پنل کاربری',
            path: "/",
            isActive: true
        },
        {
            title: 'آموزش، اپلیکیشن ها، سرور ها',
            path: "/instructions",
            isActive: true
        }
    ]
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                آموزش، اپلیکیشن ها، سرور ها
            </PageTitle>
            <div className="w-75">
                در این صفحه می‌توانید آموزش های اتصال و استفاده از سرویس را بررسی کنید، همچنین اپلیکیشن های مورد نیاز برای اتصال و سرور های مورد نیاز برای اتصال را دریافت کنید.
            </div>
            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">آموزش های اتصال و استفاده</h2>
                </div>
                <KTCardBody className='py-4'>
                    <div className='d-flex'>
                        <div className='w-75 w-100'>
                            <p>
                                با کلیک روی لینک زیر به صفحه پشتیبانی هوشمند کاور ترید منتقل می‌شوید که در آن می‌توانید با توجه به سیستم عامل خود و اپلیکیشن های مورد نیاز را دریافت کنید و آموزش نصب آنها را ببینید، همچنین پس از دریافت اپلیکیشن ها اتصال می‌توانید فایل های سرور را دریافت کنید.
                            </p>
                            <button className="btn btn-primary btn-sm">ورود به پشتیبانی هوشمند و دریافت اپلیکیشن ها و سرور ها</button>
                        </div>
                        <div className='w-md-25'>
                            <img
                                className='position-md-absolute d-none d-md-flex'
                                style={{top: "-20px"}}
                                src={toAbsoluteUrl('/media/guy-phone.svg')}
                                alt='آموزش ها'
                            />
                        </div>
                    </div>
                </KTCardBody>
            </KTCard>
        </EnableSidebar>
    )
}

export {InstructionPage}
