import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useListView} from "../PlanListViewProvider";
import {Plan} from "../../../../_models";
import {isNotEmpty} from "../../../../../_metronic/helpers";
import clsx from "clsx";
import {ListLoading} from "../../ListLoading";
import {useQueryResponsePlans} from "../PlanQueryResponseProvider";
import {createPlan, updatePlan} from "../../../../requests/_admin_requests";

type Props = {
    isPlanLoading: boolean
    plan: Plan
}

const editPlanSchema = Yup.object().shape({
    name: Yup.string()
        .required('نام پلن باید کامل و واضح باشد.'),
    price: Yup.string()
        .min(5, 'قیمت را به تومان وارد کنید.')
        .required('وارد کردن قیمت است.'),
    days: Yup.string()
        .min(1, 'تعداد روزها را درست وارد کنید')
        .required('وارد کردن تعداد روزها است.'),
})

const PlanEditModalForm: FC<Props> = ({plan, isPlanLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponsePlans()

    const [planForEdit] = useState<Plan>({
        ...plan
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: planForEdit,
        validationSchema: editPlanSchema,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await updatePlan(values).then(res => {
                        if (res?.status == 200) {
                            cancel(true)
                        }
                    })
                } else {
                    await createPlan(values).then(res => {
                        if (res?.status == 201) {
                            cancel(true)
                        }
                    })
                }
            } catch (e: any) {
                setErrors(e?.response?.data?.errors)
            } finally {
                setSubmitting(true)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_plan_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_plan_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_plan_header'
                    data-kt-scroll-wrappers='#kt_modal_add_plan_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>نام پلن</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            placeholder='نام پلن'
                            {...formik.getFieldProps('name')}
                            type='text'
                            name='name'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.name && formik.errors?.name},
                                {
                                    'is-valid': formik.touched.name && !formik.errors?.name,
                                }
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isPlanLoading}
                        />
                        {formik.touched.name && formik.errors?.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.name}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>روز ها</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('days')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.days && formik.errors?.days},
                                {
                                    'is-valid': formik.touched.days && !formik.errors?.days,
                                }
                            )}
                            type='text'
                            name='days'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isPlanLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.days && formik.errors?.days && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.days}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>قیمت</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('price')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.price && formik.errors?.price},
                                {
                                    'is-valid': formik.touched?.price && !formik.errors?.price,
                                }
                            )}
                            type='text'
                            name='price'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isPlanLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.price && formik.errors?.price && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.price}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>پیشوند برای نام های کاربری</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('random_username_prefix')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.random_username_prefix && formik.errors?.random_username_prefix},
                                {
                                    'is-valid': formik.touched?.random_username_prefix && !formik.errors?.random_username_prefix,
                                }
                            )}
                            type='text'
                            name='random_username_prefix'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isPlanLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.random_username_prefix && formik.errors?.random_username_prefix && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.random_username_prefix}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>وضعیت</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <div className="form-check form-check-custom form-check-solid">
                            <input
                                {...formik.getFieldProps('is_active')}
                                className={clsx(
                                    'form-check-input',
                                    {'is-invalid': formik.touched?.is_active && formik.errors?.is_active},
                                    {
                                        'is-valid': formik.touched?.is_active && !formik.errors?.is_active,
                                    }
                                )}
                                checked={formik.values.is_active}
                                disabled={formik.isSubmitting || isPlanLoading}
                                type="checkbox"
                                id="is_active"/>
                            <label className="form-check-label" htmlFor="is_active">
                                فعال
                            </label>
                        </div>
                        {/* end::Input */}
                        {formik.touched?.is_active && formik.errors?.is_active && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.is_active}</span>
                                </div>
                            </div>
                        )}

                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>توضیحات</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <textarea
                            {...formik.getFieldProps('description')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.description && formik.errors?.description},
                                {
                                    'is-valid': formik.touched.description && !formik.errors?.description,
                                }
                            )}
                            name='description'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isPlanLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.description && formik.errors?.description && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.description}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-plans-modal-action='cancel'
                        disabled={formik.isSubmitting || isPlanLoading}
                    >
                        لغو
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-plans-modal-action='submit'
                        disabled={isPlanLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isPlanLoading) && (
                            <span className='indicator-progress'>
                لطفا منتظر بمانید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isPlanLoading) && <ListLoading/>}
        </>
    )
}

export {PlanEditModalForm}
